// import { Link } from 'gatsby'

const json = {
  title: 'Nice to meet you!',
  text: `
  Hi! My name is Christian. here you can learn a little bit about me.

  I'm from Costa Rica, currently living and working here, I enjoy the beauty of this country. It's beaches, forests, mountains, volcanoes, etc. Sometimes I like to take some trips to meet new places, inside and outside the country. Taking some time to know myself and know the beauty of the places around me is what keeps me happy and motivated.

  Right now I’m 26 years old, turning 27 next november 23rd. Currently I am working as an Applications Developer at Intertec International. I’ve been there since July 17th, 2019. Also I had worked at companies like Prodigious Latin America (It was my first job as a Jr Front End Developer) and First Factory (My latest job before the one I have right now, here I worked as an Applications Developer).

  In all these jobs I got experience working with many Front End technologies. The two most used in my projects are React and Vue JS. I use those and other frameworks in my job and personal projects. As I am not allowed to share code from companies' projects you can see some of my personal projects in the projects section.

  Before working at these places I studied at Universidad Nacional de Costa Rica. I graduated as a Computer Systems Engineer. I am looking forward to start a Postgraduate education very soon. Also I like to take some courses online so I can continue improving my skills and knowledge, not just in the programming field but also in design and project management topics. I’m very passionate about my work and hopefully I’ll keep learning and growing as a professional so whatever future brings I will be prepared to face it.

  Of course I’m not all about work and study, no one is, I also have some others hobbies like going to the movies, hang out with friends, video games and, like I said at the beginning, travel and going on trips. If you want to know a little bit more about me, my work and my life here are some of my social media accounts. Feel free to take a look at them.
  
  Pura Vida! `,
}

export default json
