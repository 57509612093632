import en from './translation.en.json';
import es from './translation.es.json';

import AboutSection_EN from '../../components/AboutSection/locales/AboutSection.en.json';
import AboutSection_ES from '../../components/AboutSection/locales/AboutSection.es.json';
import Footer_EN from '../../components/Footer/locales/Footer.en.json';
import Footer_ES from '../../components/Footer/locales/Footer.es.json';
import MostUsedTechnologies_EN from '../../components/MostUsedTechnologies/locales/MostUsedTechnologies.en.json';
import MostUsedTechnologies_ES from '../../components/MostUsedTechnologies/locales/MostUsedTechnologies.es.json';
import NavBar_EN from '../../components/NavBar/locales/NavBar.en.json';
import NavBar_ES from '../../components/NavBar/locales/NavBar.es.json';
import SomePersonalProjects_EN from '../../components/SomePersonalProjects/locales/SomePersonalProjects.en.json';
import SomePersonalProjects_ES from '../../components/SomePersonalProjects/locales/SomePersonalProjects.es.json';
import AboutMeText_EN from '../../components/AboutMeText/locales/AboutMeText.en';
import AboutMeText_ES from '../../components/AboutMeText/locales/AboutMeText.es';
import ResumeText_EN from '../../components/ResumeText/locales/ResumeText.en';
import ResumeText_ES from '../../components/ResumeText/locales/ResumeText.es';
import PersonalProjects_EN from '../../components/PersonalProjects/locales/PersonalProjects.en';
import PersonalProjects_ES from '../../components/PersonalProjects/locales/PersonalProjects.es';

en['AboutSection'] = AboutSection_EN;
es['AboutSection'] = AboutSection_ES;
en['Footer'] = Footer_EN;
es['Footer'] = Footer_ES;
en['MostUsedTechnologies'] = MostUsedTechnologies_EN;
es['MostUsedTechnologies'] = MostUsedTechnologies_ES;
en['NavBar'] = NavBar_EN;
es['NavBar'] = NavBar_ES;
en['SomePersonalProjects'] = SomePersonalProjects_EN;
es['SomePersonalProjects'] = SomePersonalProjects_ES;
en['AboutMeText'] = AboutMeText_EN;
es['AboutMeText'] = AboutMeText_ES;
en['ResumeText'] = ResumeText_EN;
es['ResumeText'] = ResumeText_ES;
en['PersonalProjects'] = PersonalProjects_EN;
es['PersonalProjects'] = PersonalProjects_ES;

export { en, es };
