const json = {
  title: 'Proyectos Personales',
  description: `Aquí hay algunos proyectos personales que hice en mi tiempo libre, algunos de ellos para aprender nuevas tecnologías y otros solo por diversión. Cada uno de ellos tiene una demostración en vivo y su código está publicado en mi cuenta de GitHub.`,
  personalWebSite_title: 'Sitio Web Personal',
  personalWebSite_text:
    'Esta página, en la que estamos ahora, de hecho sirve como ejemplo, usa React y Gatsby. El botón de demostración en vivo nos llevará a la página de inicio.',
  toDoList_title: 'Lista de tareas por hacer',
  toDoList_text:
    'Este es un ejemplo para una aplicación web de un lista de tareas, este ejemplo se realizó con VueJS y Vuex.',
  webChat_title: 'Chat Web',
  webChat_text:
    'Una aplicación web de chat en vivo, este ejemplo se realizó utilizando React y Firebase para el almacenamiento, la comunicación y la autenticación.',
  flutterMondayMovies_title: 'Monday Movies',
  flutterMondayMovies_text:
    'Monday Movies es una aplicación hecha con Flutter, funciona en dispositivos iOS y Android. Busca las películas más recientes y populares en la base de datos The Movie DB y las muestra con parte de su información.\n\nEl nombre Monday Movies se debe a que solía ir a los cines los lunes, ¡qué gran nombre para esta aplicación! jaja.',
  flutterDesigns_title: 'Diseños en Flutter',
  flutterDesigns_text:
    'Estos son algunos diseños para usar con Flutter, no son completamente funcionales, solo son estilos y diseños para usar en futuros proyectos reales.',
  flutterQRReader_title: 'Flutter Lector QR',
  flutterQRReader_text:
    'Este es un proyecto de Flutter que puede escanear códigos QR para páginas web y geolocalizaciones, utiliza Streams y transformadores de Streams para administrar los datos, SQL Lite para guardar datos en la aplicación y también una API para mostrar mapas, así como muchas otras opciones que lo convierte en una aplicación más compleja. \n\nEl código QR para escanear se puede generar en esta página: \n\nhttps://www.qrcode.es/es/generador-qr-code/',
  flutterUserPreferences_title: 'Flutter Preferencias de Usuario',
  flutterUserPreferences_text:
    'Este es un proyecto de Flutter que usa las preferencias compartidas para almacenar información para usar en la aplicación. Se configura en una página de configuración. \n\nSe implementa un Drawer en esta aplicación y también implementa un patrón Singleton para usar solo una instancia de las preferencias compartidas.',
  flutterProductMarket_title: 'Flutter Mercado de Productos',
  flutterProductMarket_text:
    'Este es un proyecto de Flutter que simula un mercado donde puede cargar productos, agregarles un precio, etc. Para que otros usuarios puedan ver qué artículos están disponibles. \n\nImplementa el patrón BLOC para administrar las transmisiones con RxDart, realiza operaciones CRUD en un almacén de datos de Firebase, utiliza la cámara y la galería de imágenes de los dispositivos para cargar las imágenes e implementa la autenticación del usuario con tokens y llamadas API a Firebase.',
  flutterNewsApp_title: 'Flutter App Últimas Noticias',
  flutterNewsApp_text:
    'Esta aplicación muestra las últimas noticias de un servicio API. Utiliza el patrón Provider en lugar del patrón BLOC para administrar la información entre todos los componentes que necesitan compartir datos.',
  gatsbyWebResume_title: 'Gatsby Currículum Web',
  gatsbyWebResume_text:
    'Este es un currículum web de muestra hecho con Gatsby JS, Gatsby se usa para crear páginas estáticas usando React.',
  reactNativeLoansCalculator_title: 'Calculadora de Préstamos App',
  reactNativeLoansCalculator_text:
    'Esta es una simple applicacion hecha en React Native para Android y iOS que calcula préstamos e intereses.',
  nodeConsoleTODO_title: 'Tareas Consola',
  nodeConsoleTODO_text:
    'Esta es una applicacion basica de consola hecha en Node JS para manejar una lista de tareas.',
  nodeConsoleWeather_title: 'Clima Consola',
  nodeConsoleWeather_text:
    'Esta es una applicacion de consola hecha en Node JS para consultar el clima actual de cualquier parte del mundo.',
};

export default json;
