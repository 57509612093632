// import { Link } from 'gatsby'

const json = {
  title: 'Take a look at my resume',
  text: ` 
  Here you can find my most updated resume. Take a look and let me know what you think.
  `,
  downloadBtnText: "Download"
};

export default json;
