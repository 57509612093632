const json = {
  title: 'Personal Projects',
  description: `Here are some personal projects I've made in my free time, some of them for learning new technologies and some of the just for fun. Each one of them have a Live Demo and it's code posted in my GitHub account.`,
  personalWebSite_title: 'Personal Web Site',
  personalWebSite_text:
    "This page, the one you're at now, in fact serve as an example, it uses React and Gatsby. The Live Demo Button will take you to the home page.",
  toDoList_title: 'To Do List',
  toDoList_text:
    'This is an example for a To Do List web application, this example was made using VueJS and Vuex.',
  webChat_title: 'Web Chat',
  webChat_text:
    'A Live Chat web application, this example was made using React and Firebase for the storage, communication and authentication.',
  flutterMondayMovies_title: 'Monday Movies',
  flutterMondayMovies_text:
    'Monday Movies is an App made with Flutter, it works on iOS and Android devices. It looks for the most recent and popular movies in The Movie DB database and shows them with some of their info.\n\nThe name Monday Movies is because I use to go to the movie theaters on Mondays so what a great name for this app! haha.',
  flutterDesigns_title: 'Flutter Designs',
  flutterDesigns_text:
    'These are some designs to use with Flutter, they are not full functional, they are just styles and designs to use in future real projects.',
  flutterQRReader_title: 'Flutter QR Reader',
  flutterQRReader_text:
    'This is a Flutter project that can scan QR codes for web pages and Geolocations, it uses Streams and Streams transformers for managing the data, SQL Lite for saving data in the app and also an API for displaying maps, as well as many other options that makes it a more complex app. \n\n The QR code to scan can be generated at this page: \n\nhttps://www.qrcode.es/es/generador-qr-code/',
  flutterUserPreferences_title: 'Flutter User Preferences',
  flutterUserPreferences_text:
    'This is a Flutter project that uses the shared preferences to store information to use across the app. It is setted at a settings page. \n\n A drawer is implemented in this app and it also implements a Singleton Pattern to use only one instance of the shared preferences.',
  flutterProductMarket_title: 'Flutter Product Market',
  flutterProductMarket_text:
    'This is a Flutter project that simulates a market where you can upload products, add them a price, etc. So other users can see what items are available. \n\nIt implements the BLOC Pattern to manager the streams with RxDart, it does CRUD operations to a Firebase datastore, it uses the devices camera and image gallery to upload the images and implements user authentication with tokens and API calls to Firebase.',
  flutterNewsApp_title: 'Flutter News App',
  flutterNewsApp_text:
    'This app shows the latest news from an API service. It uses the Provider pattern instead of the BLOC pattern to manage data across all component that need to share that data.',
  gatsbyWebResume_title: 'Gatsby Web Resume',
  gatsbyWebResume_text:
    'This is a sample web resume made with Gatsby JS, Gatsby is used to create static pages using React.',
  reactNativeLoansCalculator_title: 'Loans Calculator App',
  reactNativeLoansCalculator_text:
    'This is a simple React Native App for Android and iOS to calculate loans and interest.',
  nodeConsoleTODO_title: 'TODO Console App',
  nodeConsoleTODO_text:
    'This is a basic console app made in Node JS to manage tasks like a TODO application.',
  nodeConsoleWeather_title: 'Weather Console App',
  nodeConsoleWeather_text:
    'This is a console app made in Node JS to consult the current weather in any part of the world.',
};

export default json;
