// import { Link } from 'gatsby'

const json = {
  title: '¡Echá un vistazo a mi currículum!',
  text: ` 
  Aquí podés encontrar mi currículum más actualizado. Echá un vistazo y dejame saber lo que piensas.
  `,
  downloadBtnText: "Descargar"
};

export default json;
