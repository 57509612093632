import React from 'react'
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import CustomNavLink from '../CustomLink/CustomLink'
import SocialMediaAccounts from '../../data/SocialMediaAccounts'
import './Footer.css'
// i18n
import '../../i18n'
import { withNamespaces } from 'react-i18next'

const Footer = ({ t }) => {
  return (
    <div id="Footer-container">
      <MDBFooter className="font-small pt-0">
        <MDBContainer>
          <MDBRow className="pt-5 mb-3 text-center d-flex justify-content-center">
            <MDBCol md="2" className="b-3">
              <h6 className="title font-weight-bold">
                <CustomNavLink to="/">{t(`Footer.home`)}</CustomNavLink>
              </h6>
            </MDBCol>
            <MDBCol md="2" className="b-3">
              <h6 className="title font-weight-bold">
                <CustomNavLink to="/aboutMe">{t(`Footer.aboutMe`)}</CustomNavLink>
              </h6>
            </MDBCol>
            <MDBCol md="2" className="b-3">
              <h6 className="title font-weight-bold">
                <CustomNavLink to="/projects">{t(`Footer.projects`)}</CustomNavLink>
              </h6>
            </MDBCol>
            {/* <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="#!">Help</a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="#!">Contact</a>
            </h6>
          </MDBCol> */}
          </MDBRow>
          <hr className="" style={{ margin: '0 15%' }} />
          <MDBRow className="d-flex text-center justify-content-center mb-md-0 mb-4">
            <MDBCol md="8" sm="12" className="mt-4">
              <p style={{ lineHeight: '1.7rem' }}>
                {t(`Footer.description`)}
              </p>
            </MDBCol>
          </MDBRow>
          <hr
            className="clearfix d-md-none rgba-white-light"
            style={{ margin: '10% 15% 5%' }}
          />
          <MDBRow className="pb-3">
            <MDBCol md="12">
              <div className="mb-5 flex-center">
                <a
                  href={SocialMediaAccounts.facebook}
                  target="_blank"
                  className="nav-item"
                >
                  <MDBIcon
                    fab
                    icon="facebook-f"
                    size="lg"
                    className="mx-2 white-text mr-md-4"
                  ></MDBIcon>
                </a>

                {/* <MDBIcon
                  fab
                  icon="twitter"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                ></MDBIcon> */}

                {/* <MDBIcon
                  fab
                  icon="google-plus-g"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                ></MDBIcon> */}

                <a
                  href={SocialMediaAccounts.instagram}
                  target="_blank"
                  className="nav-item"
                >
                  <MDBIcon
                    fab
                    icon="instagram"
                    size="lg"
                    className="mx-2 white-text mr-md-4"
                  ></MDBIcon>
                </a>

                <a
                  href={SocialMediaAccounts.linkedin}
                  target="_blank"
                  className="nav-item"
                >
                  <MDBIcon
                    fab
                    icon="linkedin-in"
                    size="lg"
                    className="mx-2 white-text mr-md-4"
                  ></MDBIcon>
                </a>

                {/* <MDBIcon
                  fab
                  icon="pinterest"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                ></MDBIcon> */}

                <a
                  href={SocialMediaAccounts.github}
                  target="_blank"
                  className="nav-item"
                >
                  <MDBIcon
                    fab
                    icon="github"
                    size="lg"
                    className="mx-2 white-text mr-md-4"
                  ></MDBIcon>
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid>
            {/* &copy; {new Date().getFullYear()} Copyright:
            <a href="https://www.MDBootstrap.com" alt="">
              {' '}
              MDBootstrap.com{' '}
            </a> */}
          </MDBContainer>
        </div>
      </MDBFooter>
    </div>
  )
}

export default withNamespaces()(Footer)
