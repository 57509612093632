import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  // MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  // MDBFormInline,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
} from 'mdbreact';
import { Link } from 'gatsby';
import CustomNavLink from '../CustomLink/CustomLink';
import SocialMediaAccounts from '../../data/SocialMediaAccounts';
import "./Navbar.css";
// i18n
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

const NavbarPage = ({ t }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const changeSiteLang = event => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <MDBNavbar color="white" dark expand="md">
      <MDBContainer>
        {/* <Logo /> */}
        <Link to="/" className="navbar-brand">
          <strong className="white-text">{t(`NavBar.title`)}</strong>
        </Link>
        <MDBNavbarToggler name="navbar-toggler" onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav left>
            <CustomNavLink to="/aboutMe">{t(`NavBar.aboutMe`)}</CustomNavLink>
            <CustomNavLink to="/projects">{t(`NavBar.projects`)}</CustomNavLink>
            <CustomNavLink to="/resume">{t(`NavBar.resume`)}</CustomNavLink>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <div className="d-inline">
                    <div className={`d-inline-block mr-1 flag ${i18n.languages[0] === 'en' ? 'flag-us' : 'flag-cr'}`} />
                    Lang
                  </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu left>
                  <MDBDropdownItem value="en" onClick={changeSiteLang}>
                    {/* <span className="d-inline-block mr-1 flag-2 flag-us" /> */}
                    EN
                  </MDBDropdownItem>
                  <MDBDropdownItem value="es" onClick={changeSiteLang}>
                    {/* <span className="d-inline-block mr-1 flag-2 flag-cr" /> */}
                    ES
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
            <div className="d-flex align-items-center">
              <a
                href={SocialMediaAccounts.facebook}
                target="_blank"
                className="nav-item"
              >
                <MDBIcon fab icon="facebook" className="ml-1 nav-link" />
              </a>
              {/* <CustomNavLink to="#!">
                  <MDBIcon fab icon="twitter" className="ml-1" />
                </CustomNavLink> */}
              <a
                href={SocialMediaAccounts.instagram}
                target="_blank"
                className="nav-item"
              >
                <MDBIcon fab icon="instagram" className="ml-1 nav-link" />
              </a>
              {/* <CustomNavLink to="#!">
                  <MDBIcon fab icon="pinterest" className="ml-1" />
                </CustomNavLink> */}
              <a
                href={SocialMediaAccounts.linkedin}
                target="_blank"
                className="nav-item"
              >
                <MDBIcon fab icon="linkedin" className="ml-1 nav-link" />
              </a>
              <a
                href={SocialMediaAccounts.github}
                target="_blank"
                className="nav-item"
              >
                <MDBIcon fab icon="github" className="ml-1 nav-link" />
              </a>
            </div>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default withNamespaces()(NavbarPage);
