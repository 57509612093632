// import { Link } from 'gatsby'

const json = {
  title: '¡Encantado de conocerte!',
  text: `
  ¡Hola! Mi nombre es Christian. Aquí puedes aprender un poco sobre mí.

  Soy de Costa Rica, actualmente vivo y trabajo aquí, disfruto de la belleza de este país. Sus playas, bosques, montañas, volcanes. A veces me gusta hacer algunos viajes para conocer nuevos lugares, dentro y fuera del país. Tomando un tiempo para conocerme y conocer la belleza de los lugares que me rodean es lo que me mantiene feliz y motivado.

  Ahora mismo tengo 26 años y cumpliré 27 el próximo 23 de noviembre. Actualmente estoy trabajando como desarrollador de aplicaciones en Intertec International. He estado allí desde el 17 de julio de 2019. También he trabajado en empresas como Prodigious América Latina (Fue mi primer trabajo como Desarrollador Jr en Front End) y First Factory (mi último trabajo antes del que tengo actualmente, aquí trabajé como desarrollador de aplicaciones).

  En todos estos trabajos obtuve experiencia trabajando con muchas tecnologías de Front End, las dos más utilizados en mis proyectos son React y Vue JS. yo uso esos y otros frameworks en mi trabajo y proyectos personales. Como no está permitido compartir código de proyectos de empresas, pueden ver algunos de mis proyectos personales en la sección de proyectos.

  Antes de trabajar en estos lugares estudié en la Universidad Nacional de Costa Rica. Me gradué como ingeniero en sistemas informáticos. Estoy buscando comenzar una educación posgrado muy pronto. Tambien me gusta tomar algunos cursos en línea para poder continuar mejorando mis habilidades y conocimientos, no solo en el campo de la programación sino también en diseño y temas de gestión de proyectos. Me apasiona mi trabajo y espero poder seguir aprendiendo y creciendo como profesional, así que lo que sea que me depare el futuro estaré preparado para enfrentarlo.

  Por supuesto, no todo es trabajo y estudio, no debe de ser así, también tengo algunos otros pasatiempos como ir al cine, salir con amigos, video juegos y, como dije al principio, viajar y salir de paseo. Si quieren saber un poco más sobre mí, mi trabajo y mi vida aquí están algunas de mis cuentas de redes sociales. Siéntanse libres de echarles un vistazo.
  
  ¡Pura vida! `,
}

export default json
